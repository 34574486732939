.business-growth-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      url("../../images/business-growth-bg.jpg");}



.business-diversification-service{
  background-position: center;
  background-size: cover !important;
  background: url("../../images/business-diversification-service.jpg");
}