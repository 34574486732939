.billing-service-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      url("../../images/billing-services/billing-service-img.jpg");}


.buyer-account-management{
  background-image: url("../../images/billing-services/buyer-management-img.jpg");
}

.seller-account-management{
  background-image: url("../../images/billing-services/seller-management-img.jpg");
}
.transport-management-system{
  background-image: url("../../images/billing-services/transport-management-img.jpg");
}