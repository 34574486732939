@media screen and (min-width:992px){
    .modal{
    display: flex !important;
    align-items: center ;
    justify-self: center;
    margin-top: 30px;
}
}
.details-body{
    width: 100%;
}
@media screen and (max-width:1024px){
    .scrollableView{
    height: 250px;
    overflow-y: auto;
    border-bottom: 1px solid #3ECF8E;
    margin-bottom: 20px;
}
}
/* .modal.show .modal-dialog{
    z-index: 9999;
} */
.form-title{
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
}
.form-header{
    background-color: #3ECF8E;
}
 .modal-header .btn-close{
    color: white !important;
    background: url("../../images/close.png") !important;
    opacity: 1;
    background-repeat: no-repeat;
    height: 8px !important;
    width: 8px !important;
    margin-right: 0 !important;
    outline: none !important;
}
.input-container label{
    color: #000;
    font-size: 16px;
}

.input-container span{
    color: rgb(187, 7, 7);
    font-size: 12px;
}
.input-container input,
.input-container--mod input,
.input-container select,
.input-container textarea{
    border-radius: 4px;
    border: 1px solid #000;
    padding: 8px;
    width: 100%;
    outline: none !important;
    font-size: 14px;
}

.resend-otp{
font-size: 14px;
color: #000;
}
.resend-otp span{
    color: #3ECF8E;
}

 .Formbutton{
    background-color: #3ECF8E;
    color: white;
    outline: none;
    border: 1px solid #3ECF8E;
    border-radius: 8px;
    padding: 6px 16px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
@media screen and (max-width:769px){
    .form-title{
        font-size: 16px;
      margin-right: 10px !important;
    }
}