.farmUpdateBanner{
    background-position: bottom;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),url("../../images/beekeeping.jpg");
}

.processing-machinery-provider-bg{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),url("../../images/gbrHome/machinery.webp");

}
.updateSection1,.farm-update-section h3,
.farm-update-section h4{
    text-align: center;
}

.updateSection1 .container-para{
    margin-bottom: 0;
}
.farm-update-section h4{
    margin: 0 20px;
}

.updateSection1 {
    padding-bottom: 42px;
}
.ExploreClubs{
    padding-top: 70px;
}
/* .farm-update-section{
    margin-bottom: 50px;
} */
.farm-update-container .navbarRight{
width: 100% !important;
}

.farm-update-container .dropdown-menu li{
    background-color: transparent !important;
}

.farm-update-container .dropdown-menu li a{
    color: #808080 !important;

}
.farm-update-container .dropdown-menu{
    margin-top:5px ;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);}

.farm-update-container .dropdown-menu::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}
/* .farm-update-container .navbarRight{
    margin: 0 0 0 10px;
} */

.farm-update-card p,
.farm-update-card h6{
    font-size: 14px;
    color: #808080;
}


@media screen and (max-width:992px) and (min-width:769px){
    .farm-update-container .videosWrapper{
        width: 45% !important;
    }
}


/* @media screen and (max-width:769px) {
 .farm-update-container .homepage-nav-item{
    padding: 10px 0 0 !important;
 }
} */
.gbr-farm-cards{
   box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
   /* 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) */
   border-radius: 8px;
}
.gbr-farm-cards-image-container{
    height: 180px;
    object-fit: cover;
}

.image-place-holder,.gbr-farm-cards-image-container img{
width: 100%;
height: 100%;
border-top-right-radius: 8px;
border-top-left-radius: 8px;
}
.image-place-holder{
    background-color: #dfdfdf;
}
.farm-update-card{
    padding: 10px 20px ;
}

@media screen and (max-width:769px){
    .ExploreClubs{
        padding-top: 50px;
    }
}