.POBanner{
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/Po/main.jpg") !important;
}

.ProvenanceBanner{
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/agronomist-examines-growing-melon-seedlings-farm-farmers-researchers-analysis-plant.jpg") !important;

}

.QualityAssuranceBanner{
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/quality-assurance.jpg") !important;

}