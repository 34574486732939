.for-farmer-section2 img{
    width: 100%;
    height: auto;
    
    }
    .for-farmer-section2 a{
        text-decoration: none !important;
    }
    
    
    
    @media screen and (min-width:992px){
        .image-container{
            overflow: hidden;
            width: 100%;
            height: 237px;
            overflow: hidden;
        }
        
        .image-container img {
            height: 237px;
            width: 100%;
            transition: 0.5s all ease-in-out;
            object-fit: cover;
        }
        
        .image-container:hover img {
            transform: scale(1.2);
        }

        .image-container-collections {
            height: 100px;
          }
          .image-container-collections img {
            height: 100px;
          }
    }
    .image-caption {
        color: #000;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
      }
    .benefitsCardContainer{
        border-radius: 4px;
        padding: 30px;
    }
    .benefitsCardContainer:hover{
      box-shadow:0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
    }
    .benefitsCardContainer img{
        height: 60px;
        width: 60px;
    }
    .benefitsCardContainer h3{
        font-size: 16px;
        line-height: 26px;
        color: #121212bf;
        font-weight: 700;
    }
    
    .benefitsCardContainer p{
        font-size: 16px;
        line-height: 24px;
        color: #323131bf;
    }
    
    
    @media screen and (max-width:769px){
        .benefitsCardContainer{
            box-shadow: 0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
            padding: 20px;
        }
    }
    